<script lang="ts" setup>
withDefaults(
  defineProps<{
    darkMode?: true | false | 'auto'
    color?: OzBoxColors | null
    radius?: 0 | 8 | 10 | 12 | 14 | 16 | 20 | 24
  }>(),
  {
    darkMode: 'auto',
    radius: 20,
    color: null,
  },
)
</script>

<script lang="ts">
export enum OzBoxColors {
  /** Base page background color. Light mode: white. Dark mode: black. */
  Primary = 'Primary',

  /** Used for popovers. Light mode: common-ui-l86. Dark-mode: common-ui-l86 */
  PrimaryBlur = 'PrimaryBlur',

  /** Used for cross library selection menu & layout picker. Light mode: light-ui-100. Dark-mode: dark-ui-100 */
  PrimaryLight = 'PrimaryLight',

  /** Used for section boxes over Primary background. Light mode: light grey. Dark mode: dark grey */
  Secondary = 'Secondary',

  /** Color of active/selected items. Light mode: grape. Dark mode: canary */
  Selection = 'Selection',

  /** Color of a highlighted section. Light mode: light canary. Dark mode: dark-ui */
  Highlight = 'Highlight',

  /** Light mode: tangerine. Dark mode: dark-ui. */
  Destructive = 'Destructive',

  /** Light mode: padlet pink. Dark mode: dark-ui. */
  Pink = 'Pink',

  /** Color of a highlighted section. Light mode: park. Dark mode: dark-ui */
  Green = 'Green',

  /** Color of a highlighted section. Light mode: tangerine. Dark mode: dark-ui. */
  Tangerine = 'Tangerine',

  /** Color of a highlighted section. Light mode: grape. Dark mode: dark-ui. */
  Grape = 'Grape',

  /** Color of a disabled section in the delete account page. Light mode: grey-0. Dark mode: grey-850. */
  Grey = 'Grey',

  /** Light mode: oceanic. Dark mode: dark-ui. */
  Oceanic = 'Oceanic',

  /** Light mode: scarlet-0. Dark mode: dark-ui-200. */
  Scarlet = 'Scarlet',

  /** Color of a post attachment/sub-element for a post with default color. Light mode: light-ui-200. Dark mode: dark-ui-200. */
  PostDefault = 'PostDefault',

  /** Color of a post attachment/sub-element for a red post. Light mode: scarlet-200. Dark mode: scarlet-800. */
  PostRed = 'PostRed',

  /** Color of a post attachment/sub-element for an orange post. Light mode: canary-200. Dark mode: tangerine-800. */
  PostOrange = 'PostOrange',

  /** Color of a post attachment/sub-element for a green post. Light mode: park-200. Dark mode: park-800. */
  PostGreen = 'PostGreen',

  /** Color of a post attachment/sub-element for a blue post. Light mode: oceanic-200. Dark mode: oceanic-800. */
  PostBlue = 'PostBlue',

  /** Color of a post attachment/sub-element for a purple post. Light mode: grape-200. Dark mode: grape-800. */
  PostPurple = 'PostPurple',

  /** Color of done tasks. */
  TaskDone = 'TaskDone',

  /** Color of popovers on top of modals */
  Popover = 'Popover',

  /** No color. OzBox will just provide the shape. */
  None = 'None',
}

export default {}
</script>

<template>
  <div
    :class="[
      // Shape
      radius === 8 && 'rounded-lg',
      radius === 10 && 'rounded-[10px]',
      radius === 12 && 'rounded-xl',
      radius === 14 && 'rounded-[14px]',
      radius === 16 && 'rounded-2xl',
      radius === 20 && 'rounded-2.5xl',
      radius === 24 && 'rounded-3xl',
      // Colors
      color === OzBoxColors.Primary && [
        {
          'bg-white-100': darkMode === false,
          'bg-nightfall-900': darkMode === true,
          'bg-white-100 dark:bg-nightfall-900': darkMode === 'auto',
        },
        {
          'text-dark-text-100': darkMode === false,
          'text-light-text-100': darkMode === true,
          'text-dark-text-100 dark:text-light-text-100': darkMode === 'auto',
        },
      ],
      color === OzBoxColors.Secondary && [
        {
          'bg-light-ui-200': darkMode === false,
          'bg-dark-ui-200': darkMode === true,
          'bg-light-ui-200 dark:bg-dark-ui-200': darkMode === 'auto',
        },
        {
          'text-dark-text-100': darkMode === false,
          'text-light-text-100': darkMode === true,
          'text-dark-text-100 dark:text-light-text-100': darkMode === 'auto',
        },
      ],
      color === OzBoxColors.PrimaryLight && [
        {
          'bg-light-ui-100': darkMode === false,
          'bg-dark-ui-100': darkMode === true,
          'bg-light-ui-100 dark:bg-dark-ui-100': darkMode === 'auto',
        },
        {
          'text-dark-text-100': darkMode === false,
          'text-light-text-100': darkMode === true,
          'text-dark-text-100 dark:text-light-text-100': darkMode === 'auto',
        },
      ],
      color === OzBoxColors.Selection && [
        {
          'bg-grape-500': darkMode === false,
          'bg-canary-500': darkMode === true,
          'bg-grape-500 dark:bg-canary-500': darkMode === 'auto',
        },
        {
          'text-light-text-100': darkMode === false,
          'text-dark-text-100': darkMode === true,
          'text-light-text-100 dark:text-dark-text-100': darkMode === 'auto',
        },
      ],
      color === OzBoxColors.Highlight && [
        {
          'bg-canary-0': darkMode === false,
          'bg-dark-ui-200': darkMode === true,
          'bg-canary-0 dark:bg-dark-ui-200': darkMode === 'auto',
        },
        {
          'text-light-text-100': darkMode === true,
          'text-dark-text-100': darkMode === false,
          'text-dark-text-100 dark:text-light-text-100': darkMode === 'auto',
        },
      ],
      color === OzBoxColors.Destructive && [
        {
          'bg-tangerine-0': darkMode === false,
          'bg-dark-ui-200': darkMode === true,
          'bg-tangerine-0 dark:bg-dark-ui-200': darkMode === 'auto',
        },
        {
          'text-light-text-100': darkMode === true,
          'text-dark-text-100': darkMode === false,
          'text-dark-text-100 dark:text-light-text-100': darkMode === 'auto',
        },
      ],
      color === OzBoxColors.Pink && [
        {
          'bg-padlet-pink-0': darkMode === false,
          'bg-dark-ui-200': darkMode === true,
          'bg-padlet-pink-0 dark:bg-dark-ui-200': darkMode === 'auto',
        },
        {
          'text-light-text-100': darkMode === true,
          'text-dark-text-100': darkMode === false,
          'text-dark-text-100 dark:text-light-text-100': darkMode === 'auto',
        },
      ],
      color === OzBoxColors.Green && [
        {
          'bg-park-0': darkMode === false,
          'bg-dark-ui-200': darkMode === true,
          'bg-park-0 dark:bg-dark-ui-200': darkMode === 'auto',
        },
        {
          'text-light-text-100': darkMode === true,
          'text-dark-text-100': darkMode === false,
          'text-dark-text-100 dark:text-light-text-100': darkMode === 'auto',
        },
      ],
      color === OzBoxColors.Tangerine && [
        {
          'bg-tangerine-0': darkMode === false,
          'bg-dark-ui-200': darkMode === true,
          'bg-tangerine-0 dark:bg-dark-ui-200': darkMode === 'auto',
        },
        {
          'text-light-text-100': darkMode === true,
          'text-dark-text-100': darkMode === false,
          'text-dark-text-100 dark:text-light-text-100': darkMode === 'auto',
        },
      ],
      color === OzBoxColors.Grape && [
        {
          'bg-grape-0': darkMode === false,
          'bg-dark-ui-200': darkMode === true,
          'bg-grape-0 dark:bg-dark-ui-200': darkMode === 'auto',
        },
        {
          'text-light-text-100': darkMode === true,
          'text-dark-text-100': darkMode === false,
          'text-dark-text-100 dark:text-light-text-100': darkMode === 'auto',
        },
      ],
      color === OzBoxColors.Oceanic && [
        {
          'bg-oceanic-0': darkMode === false,
          'bg-dark-ui-200': darkMode === true,
          'bg-oceanic-0 dark:bg-dark-ui-200': darkMode === 'auto',
        },
        {
          'text-light-text-100': darkMode === true,
          'text-dark-text-100': darkMode === false,
          'text-dark-text-100 dark:text-light-text-100': darkMode === 'auto',
        },
      ],
      color === OzBoxColors.Grey && [
        {
          'bg-grey-0': darkMode === false,
          'bg-grey-850': darkMode === true,
          'bg-grey-0 dark:bg-grey-850': darkMode === 'auto',
        },
        {
          'text-light-text-300': darkMode === true,
          'text-dark-text-300': darkMode === false,
          'text-dark-text-300 dark:text-light-text-300': darkMode === 'auto',
        },
      ],
      color === OzBoxColors.Scarlet && [
        {
          'bg-scarlet-0': darkMode === false,
          'bg-dark-ui-200': darkMode === true,
          'bg-scarlet-0 dark:bg-dark-ui-200': darkMode === 'auto',
        },
        {
          'text-light-text-100': darkMode === true,
          'text-dark-text-100': darkMode === false,
          'text-dark-text-100 dark:text-light-text-100': darkMode === 'auto',
        },
      ],
      color === OzBoxColors.PostDefault && [
        {
          'bg-light-ui-200': darkMode === false,
          'bg-dark-ui-200': darkMode === true,
          'bg-light-ui-200 dark:bg-dark-ui-200': darkMode === 'auto',
        },
        {
          'text-light-text-100': darkMode === true,
          'text-dark-text-100': darkMode === false,
          'text-dark-text-100 dark:text-light-text-100': darkMode === 'auto',
        },
      ],
      color === OzBoxColors.PostRed && [
        {
          'bg-scarlet-200': darkMode === false,
          'bg-scarlet-800': darkMode === true,
          'bg-scarlet-200 dark:bg-scarlet-800': darkMode === 'auto',
        },
        {
          'text-light-text-100': darkMode === true,
          'text-dark-text-100': darkMode === false,
          'text-dark-text-100 dark:text-light-text-100': darkMode === 'auto',
        },
      ],
      color === OzBoxColors.PostOrange && [
        {
          'bg-canary-200': darkMode === false,
          'bg-tangerine-800': darkMode === true,
          'bg-canary-200 dark:bg-tangerine-800': darkMode === 'auto',
        },
        {
          'text-light-text-100': darkMode === true,
          'text-dark-text-100': darkMode === false,
          'text-dark-text-100 dark:text-light-text-100': darkMode === 'auto',
        },
      ],
      color === OzBoxColors.PostGreen && [
        {
          'bg-park-200': darkMode === false,
          'bg-park-800': darkMode === true,
          'bg-park-200 dark:bg-park-800': darkMode === 'auto',
        },
        {
          'text-light-text-100': darkMode === true,
          'text-dark-text-100': darkMode === false,
          'text-dark-text-100 dark:text-light-text-100': darkMode === 'auto',
        },
      ],
      color === OzBoxColors.PostBlue && [
        {
          'bg-oceanic-200': darkMode === false,
          'bg-oceanic-800': darkMode === true,
          'bg-oceanic-200 dark:bg-oceanic-800': darkMode === 'auto',
        },
        {
          'text-light-text-100': darkMode === true,
          'text-dark-text-100': darkMode === false,
          'text-dark-text-100 dark:text-light-text-100': darkMode === 'auto',
        },
      ],
      color === OzBoxColors.PostPurple && [
        {
          'bg-grape-200': darkMode === false,
          'bg-grape-800': darkMode === true,
          'bg-grape-200 dark:bg-grape-800': darkMode === 'auto',
        },
        {
          'text-light-text-100': darkMode === true,
          'text-dark-text-100': darkMode === false,
          'text-dark-text-100 dark:text-light-text-100': darkMode === 'auto',
        },
      ],
      color === OzBoxColors.PrimaryBlur && [
        {
          'bg-common-ui-l86': darkMode === false,
          'bg-common-ui-d86': darkMode === true,
          'bg-common-ui-l86 dark:bg-common-ui-d86': darkMode === 'auto',
          'backdrop-blur-10': true,
        },
        {
          'text-light-text-100': darkMode === true,
          'text-dark-text-100': darkMode === false,
          'text-dark-text-100 dark:text-light-text-100': darkMode === 'auto',
        },
      ],
      color === OzBoxColors.TaskDone && [
        {
          'bg-oceanic-0': darkMode === false,
          'bg-oceanic-1000': darkMode === true,
          'bg-oceanic-0 dark:bg-oceanic-1000': darkMode === 'auto',
        },
        {
          'text-light-text-100': darkMode === true,
          'text-dark-text-100': darkMode === false,
          'text-dark-text-100 dark:text-light-text-100': darkMode === 'auto',
        },
      ],
      color === OzBoxColors.Popover && [
        {
          'bg-popover-light': darkMode === false,
          'bg-popover-dark': darkMode === true,
          'bg-popover-light dark:bg-popover-dark': darkMode === 'auto',
        },
        {
          'text-light-text-100': darkMode === true,
          'text-dark-text-100': darkMode === false,
          'text-dark-text-100 dark:text-light-text-100': darkMode === 'auto',
        },
      ],
    ]"
  >
    <!-- @slot Contents of the box. -->
    <slot></slot>
  </div>
</template>
